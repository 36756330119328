import api from '@/services/Api'

export default {
    sommaire () {
        return api().get('/facturation/sommaire')
    },
    integrite () {
        return api().get('/facturation/integrite')
    },
    creerfactures (periode) {
        return api().get(`/facturation/creerfactures/${periode}`)
    },
    payerFactures () {
        return api().get('/facturation/payerFactures')
    },
    payerFacturesParId (id) {
        return api().get(`/facturation/payerFacturesParId/${id}`)
    },
    creerlot (periode) {
        return api().get(`/facturation/creerlot/${periode}`)
    },
    informerClient () {
        return api().get('/facturation/informerClient')
    },
    updateFacture (facture, factureId) { // Mise à jour d'une entrée spécifique
        return api().put(`/facturation/updateFacture/${factureId}`, facture)
    },
    getFactureHtml (factureId) {
        return api().get(`/facturation/getFactureHtml/${factureId}`)
    },
}
