<template>
  <v-container
    id="regular-forms"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
      >
        <validation-observer
          ref="obs"
          v-slot="{ handleSubmit }"
        >
          <base-material-card
            :color="typeEdit === 1 ? 'green' : 'primary'"
            inline
            icon="mdi-receipt"
            :title="edit ? $t('Billing_edit') : $t('Billing_info')"
            class="px-5 py-3"
          >
            <form>
              <!--  ID-->
              <v-row
                align="center"
                dense
              >
                <v-col
                  class="text-right body-1 grey--text mr-4"
                  cols="2"
                >
                  <!--  Editable-->
                  <span v-if="edit">ID</span>
                  <!--  Non Editable-->
                  <span v-else>ID:</span>
                </v-col>

                <v-col cols="8">
                  <!--  Editable-->
                  <v-text-field
                    v-if="edit"
                    v-model="submitForm.id"
                    disabled
                  />
                  <!--  Non Editable-->
                  <span v-else>{{ submitForm.id }}</span>
                </v-col>
              </v-row>

              <!--  ID-->
              <v-row
                align="center"
                dense
              >
                <v-col
                  class="text-right body-1 grey--text mr-4"
                  cols="2"
                >
                  <!--  Editable-->
                  <span v-if="edit">{{ $t('contract.bill.Supplier_amount_before_tax') }}</span>
                  <!--  Non Editable-->
                  <span v-else>{{ $t('contract.bill.Supplier_amount_before_tax') }}:</span>
                </v-col>

                <v-col cols="8">
                  <span v-if="submitForm.montant_fournisseur_at">{{ submitForm.montant_fournisseur_at.toFixed(2) }} $</span>
                </v-col>
              </v-row>

              <!--  Frais autre-->
              <v-row
                align="center"
                dense
              >
                <v-col
                  class="text-right body-1 grey--text mr-4"
                  cols="2"
                >
                  <!--  Editable-->
                  <span v-if="edit">{{ $t('contract.bill.Other_charge') }}</span>
                  <!--  Non Editable-->
                  <span v-else>{{ $t('contract.bill.Other_charge') }}:</span>
                </v-col>

                <v-col cols="8">
                  <span v-if="submitForm.frais_autre">{{ submitForm.frais_autre.toFixed(2) }} $</span>
                </v-col>
              </v-row>

              <!--  Frais administration-->
              <v-row
                align="center"
                dense
              >
                <v-col
                  class="text-right body-1 grey--text mr-4"
                  cols="2"
                >
                  <!--  Editable-->
                  <span v-if="edit">{{ $t('contract.bill.Administrative_costs') }}</span>
                  <!--  Non Editable-->
                  <span v-else>{{ $t('contract.bill.Administrative_costs') }}:</span>
                </v-col>

                <v-col cols="8">
                  <span v-if="submitForm.frais_administration">{{ submitForm.frais_administration.toFixed(2) }} $</span>
                </v-col>
              </v-row>

              <!--  Montant avant taxe-->
              <v-row
                align="center"
                dense
              >
                <v-col
                  class="text-right body-1 grey--text mr-4"
                  cols="2"
                >
                  <!--  Editable-->
                  <span v-if="edit">{{ $t('contract.bill.Total_before_tax') }}</span>
                  <!--  Non Editable-->
                  <span v-else>{{ $t('contract.bill.Total_before_tax') }}:</span>
                </v-col>

                <v-col cols="8">
                  <span v-if="submitForm.montant_at">{{ submitForm.montant_at.toFixed(2) }} $</span>
                </v-col>
              </v-row>

              <!--  TPS-->
              <v-row
                align="center"
                dense
              >
                <v-col
                  class="text-right body-1 grey--text mr-4"
                  cols="2"
                >
                  <!--  Editable-->
                  <span v-if="edit">TPS</span>
                  <!--  Non Editable-->
                  <span v-else>TPS:</span>
                </v-col>

                <v-col cols="8">
                  <span v-if="submitForm.tps">{{ submitForm.tps.toFixed(2) }} $</span>
                </v-col>
              </v-row>

              <!--  TVQ-->
              <v-row
                align="center"
                dense
              >
                <v-col
                  class="text-right body-1 grey--text mr-4"
                  cols="2"
                >
                  <!--  Editable-->
                  <span v-if="edit">TVQ</span>
                  <!--  Non Editable-->
                  <span v-else>TVQ:</span>
                </v-col>

                <v-col cols="8">
                  <span v-if="submitForm.tvq">{{ submitForm.tvq.toFixed(2) }} $</span>
                </v-col>
              </v-row>

              <!--  Frais ajustement (Lecture)-->
              <v-row
                align="center"
                dense
              >
                <v-col
                  class="text-right body-1 grey--text mr-4"
                  cols="2"
                >
                  <!--  Editable-->
                  <span>{{ $t('Adjustment_fee') }}:</span>
                </v-col>

                <v-col cols="8">
                  <!--  Non Editable-->
                  <span>{{ submitForm.frais_ajustement }} $</span>
                </v-col>
              </v-row>

              <!--  Montant total-->
              <v-row
                align="center"
                dense
              >
                <v-col
                  class="text-right body-1 grey--text mr-4"
                  cols="2"
                >
                  <!--  Editable-->
                  <span v-if="edit">Total</span>
                  <!--  Non Editable-->
                  <span v-else>Total:</span>
                </v-col>

                <v-col cols="8">
                  <span v-if="submitForm.montant_total">{{ submitForm.montant_total.toFixed(2) }} $</span>
                </v-col>
              </v-row>

              <!--  Frais ajustement (Edition)-->
              <v-row
                align="center"
                dense
              >
                <v-col
                  class="text-right body-1 grey--text mr-4"
                  cols="2"
                >
                  <!--  Editable-->
                  <span v-if="edit">{{ $t('New_adjustment_fee') }}*</span>
                </v-col>

                <v-col cols="8">
                  <!--  Editable-->
                  <validation-provider
                    v-if="edit"
                    v-slot="{ errors, valid }"
                    :name="$t('New_adjustment_fee')"
                    :rules="{ required: true, regex: regexMontant }"
                  >
                    <v-text-field
                      v-model="submitForm.newFrais_ajustement"
                      type="number"
                      :error-messages="errors"
                      :success="valid"
                      required
                    />
                  </validation-provider>
                </v-col>
              </v-row>

              <!--  Nouvelle Note (Édition seulement)-->
              <v-row
                v-if="edit"
                align="center"
                dense
              >
                <v-col
                  class="text-right body-1 grey--text mr-4"
                  cols="2"
                >
                  <!--  Editable-->
                  <span v-if="edit">Note*</span>
                  <!--  Non Editable-->
                  <span v-else>Note:</span>
                </v-col>

                <v-col cols="8">
                  <validation-provider
                    v-slot="{ errors, valid }"
                    name="Note"
                    rules="required"
                  >
                    <!--  Editable-->
                    <v-textarea
                      v-model="submitForm.newNote"
                      rows="3"
                      auto-grow
                      append-icon="mdi-comment"
                      :error-messages="errors"
                      :success="valid"
                      required
                      :disabled="!edit"
                    />
                  </validation-provider>
                </v-col>
              </v-row>

              <!--  Note (Lecture seulement)-->
              <v-row
                v-if="!edit"
                align="center"
                dense
              >
                <v-col
                  class="text-right body-1 grey--text mr-4"
                  cols="2"
                >
                  <!--  Editable-->
                  <span v-if="edit">Note</span>
                  <!--  Non Editable-->
                  <span v-else>Note:</span>
                </v-col>

                <v-col cols="8">
                  <v-card
                    v-for="(item, i) in submitForm.note"
                    :key="i"
                    variant="outlined"
                    class="mt-5"
                  >
                    <v-card-text>
                      <div>
                        <div class="text-h4 ml-2">
                          {{ $t('Adjustment_fee') }} : {{ item.fraisAjustement }} $
                        </div>
                        <div class="text-overline ml-2">
                          {{ item.user.displayName }} {{ item.date }}
                        </div>
                        <div class="text-caption ml-2">
                          <v-textarea
                            :value="item.note"
                            auto-grow
                            variant="outlined"
                            rows="1"
                            row-height="15"
                            append-icon="mdi-comment"
                            disabled
                          />
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <!--  Mot (Champs requis)-->
              <v-col
                v-if="edit"
                cols="2"
              >
                <div class="body-2 font-weight-light">
                  *{{ $t('Required_Fields') }}
                </div>
              </v-col>

              <div
                v-if="edit"
                class="pa-3 text-center"
              >
                <!--  Bouton annuler-->
                <v-btn
                  color="error"
                  @click="cancel"
                >
                  {{ $t('Cancel') }}
                </v-btn>
                <!--  Bouton submit-->
                <v-btn
                  color="success"
                  class="ml-3"
                  @click="handleSubmit(submit)"
                >
                  {{ $t('Validate') }}
                </v-btn>
              </div>
            </form>
          </base-material-card>
        </validation-observer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  // Service
  import FacturesService from '@/services/01Cell/FacturesService'
  import FacturationsService from '@/services/01Cell/FacturationsService'
  // Utilities
  import { mapState } from 'vuex'
  import { format } from 'date-fns'

  // Other
  import {
    ValidationObserver,
    ValidationProvider,
  } from 'vee-validate'

  export default {
    name: 'ContractFactureMensuelleEdit',
    components: {
      ValidationObserver,
      ValidationProvider,
    },
    props: {
      edit: Boolean,
      typeEdit: Number,
    },
    data () {
      return {
        submitForm: {},
        submitFormReset: {},
      }
    },

    computed: {
      ...mapState(['userInfo']),
      regexMontant () {
        return /^-?\d+(\.\d{1,2})?$/
      },
    },

    methods: {
      async resetSubmitForm (id) {
        // Reset da la validation
        this.$refs.obs.reset()

        this.submitForm = {}
        try {
          this.submitForm = await FacturesService.view(id)
          this.submitFormReset = JSON.parse(JSON.stringify(this.submitForm))

          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      async submit () {
        // Start Spinner
        this.$loading.show()
        const note = {
          note: this.submitForm.newNote,
          user: this.userInfo.info,
          date: format(Date.now(), 'yyyy-MM-dd'),
          fraisAjustement: this.submitForm.newFrais_ajustement,
        }

        this.submitForm.frais_ajustement = parseFloat(this.submitForm.newFrais_ajustement)
        try {
          if (this.submitForm.note) {
            this.submitForm.note.push(note)
          } else {
            this.submitForm.note = [note]
          }

          await FacturationsService.updateFacture(this.submitForm, this.submitForm.id)

          this.$emit('close-dialog')
        } catch (error) {
          // Stop Spinner
          this.$loading.hide()
          console.log(error)
        }
      },
      cancel () {
        this.$emit('close-dialog')
      },
    },
  }
</script>
